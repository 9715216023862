import { Box, IconButton, Tooltip } from '@mui/material';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';

interface CopyToClipboardButtonProps {
    text: string;
    small?: boolean;
    disabled?: boolean;
    tooltip?: React.ReactNode;
    icon?: React.ReactNode;
    size?: 'large' | 'medium' | 'small';
}

function CopyToClipboardButton({
    text,
    small,
    disabled,
    icon,
    size = 'small',
    tooltip = 'Copy to Clipboard',
}: CopyToClipboardButtonProps) {
    const [showCopied, setShowCopied] = React.useState(false);

    React.useEffect(() => {
        if (!showCopied) {
            return;
        }

        const timeout = window.setTimeout(() => setShowCopied(false), 2000);

        return () => {
            clearTimeout(timeout);
        };
    }, [showCopied]);

    return (
        <Tooltip
            placement="top"
            title={
                showCopied ? (
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <CheckIcon sx={{ marginRight: 1 }} fontSize="small" /> Copied
                    </Box>
                ) : (
                    tooltip
                )
            }
        >
            <IconButton
                disabled={disabled}
                size={size}
                onClick={async () => {
                    await navigator.clipboard.writeText(text);
                    setShowCopied(true);
                }}
            >
                {icon ?? <FileCopyOutlined fontSize={small ? 'small' : 'medium'} />}
            </IconButton>
        </Tooltip>
    );
}

export default CopyToClipboardButton;
