import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
    Box,
    Typography,
    Button,
    Avatar,
    IconButton
} from '@mui/material';
// @ts-ignore
import { useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '../../model/profile';
import { handleError } from '../../components/snackbar/reducer';
import axios from 'axios';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';
import Player from './components/Player';
import PraccPaperHeader from '../../components/paper/PraccPaperHeader';
import { AsyncChatContent } from '../../containers/app/chat/chat-container';
import { PraccDialog } from 'components/layout/dialogs';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SettingIcon from '../../assets/lobbies/setting.png';
import CopyIcon from '../../assets/lobbies/copy.svg';
import RefreshIcon from '../../assets/lobbies/refresh.svg';
import ExitLineIcon from '../../assets/lobbies/exit-line.svg';
import MinusIcon from '../../assets/lobbies/minus.svg';
import PlusIcon from '../../assets/lobbies/plus.svg';
import UserIcon from '../../assets/lobbies/user.svg';
import { SiFaceit } from 'react-icons/si';
import SteamIcon from '../../assets/lobbies/steam-icon.png';
import MicDisabled from '../../assets/lobbies/mic-disabled.svg';
import MicEnabled from '../../assets/lobbies/mic-enabled.svg';
import ShieldCheckEnabled from '../../assets/lobbies/shield-check.svg';
import SlotUser from '../../assets/lobbies/slot-user.svg';
import MicSlashDisabled from '../../assets/lobbies/mic-disabled.png';
import Mic from '../../assets/lobbies/mic.png';
import FireOnIcon from '../../assets/lobbies/fire-on.png';
import AcceptIcon from '../../assets/lobbies/accept.svg';
import RejectIcon from '../../assets/lobbies/reject.svg';
import ExchangeIcon from '../../assets/lobbies/exchange.svg';
import KickIcon from '../../assets/lobbies/delete.png';
import csgoMaps from '../../utils/maps';
import { usePing } from 'utils/usePing';
import { useGameModeMaps } from './utils';

interface Request {
    ID: number;
    Avatar: string;
    FaceItElo: number;
    COIN: number;
}

const requests: Request[] = [
    {
        ID: 1,
        Avatar: 'https://picsum.photos/200/300',
        FaceItElo: 3222,
        COIN: 17890,
    },
    {
        ID: 2,
        Avatar: 'https://picsum.photos/200/300',
        FaceItElo: 3242,
        COIN: 19890,
    },
    {
        ID: 1,
        Avatar: 'https://picsum.photos/200/300',
        FaceItElo: 3222,
        COIN: 17890,
    },
    {
        ID: 2,
        Avatar: 'https://picsum.photos/200/300',
        FaceItElo: 3242,
        COIN: 19890,
    },
    {
        ID: 1,
        Avatar: 'https://picsum.photos/200/300',
        FaceItElo: 3222,
        COIN: 17890,
    },
    {
        ID: 2,
        Avatar: 'https://picsum.photos/200/300',
        FaceItElo: 3242,
        COIN: 19890,
    },
]

const RequestCard = ({ request, isCreator }: { request: Request, isCreator: Boolean }) => {
    return (
        <Box
            sx={{
                borderRadius: '10px',
                flex: 1,
                cursor: 'pointer',
                transition: 'background-size 0.2s ease-in-out',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: 'linear-gradient(130deg, rgba(0, 212, 255, 0) 0%, rgba(13, 71, 161, 0.35) 30%, rgba(0, 212, 255, 0) 60%)',
                backgroundSize: '100% auto',
                '&:hover': {
                    backgroundSize: '250% auto',
                },
                height: '165px',
                width: 'calc(100% - 11px)',
                maxWidth: '100%'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '17px',
                    alignItems: 'center',
                    gap: '11px',
                }}
            >
                <Avatar
                    src={request.Avatar}
                    sx={{
                        height: 65,
                        width: 65,
                        borderRadius: '6px',
                    }}
                />

                <Box
                    sx={{
                        maxWidth: '80px',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <Box
                            sx={{
                                width: 15,
                                height: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <SiFaceit color="#e4621b" size={10} />
                        </Box>
                        <Typography sx={{ color: 'white', fontSize: '10px', whiteSpace: 'nowrap', lineHeight: '11.72px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {request.FaceItElo ? `${request.FaceItElo} ELO` : '-'}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                        <Typography sx={{ fontWeight: '600', color: '#A7A7A7', fontSize: '10px', lineHeight: '11.72px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {`${request.COIN}`}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '5px',
                        visibility: isCreator ? 'visible' : 'hidden',
                    }}
                >
                    <Button
                        sx={{
                            width: '35px',
                            height: '22px',
                            borderRadius: '3px',
                            minWidth: '35px',
                        }}
                        variant='contained'
                    >
                        <img src={AcceptIcon} style={{ width: '20px', height: '20px' }} />
                    </Button>
                    <Button
                        sx={{
                            width: '35px',
                            height: '22px',
                            borderRadius: '3px',
                            minWidth: '35px',
                        }}
                        variant='outlined'
                    >
                        <img src={RejectIcon} style={{ width: '20px', height: '20px' }} />
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

const SwitchPlayerDialog = (props: { open: boolean, onClose: () => void }) => {
    const [player, setPlayer] = React.useState(0);

    return (
        <PraccDialog open={props.open} onClose={props.onClose}>
            <DialogTitle>SWITCH PLAYERS</DialogTitle>
            <DialogContent
                sx={{
                    maxWidth: 320
                }}
            >
                <Box>
                    <Typography>Selected to switch teams</Typography>
                    <Player sx={{ marginTop: '15px' }} selected />
                </Box>
                <Box>
                    <Typography sx={{ marginTop: '15px'}}>Select player to switch teams with</Typography>
                    <Box sx={{  }}>
                        { new Array(5).fill(0).map((_, key) => <Player
                            key={key}
                            sx={{ marginTop: '15px' }}
                            onClick={() => setPlayer(key)}
                            selected={player === key}
                        />) }
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box
                    sx={{
                        margin: '0 16px 20px',
                        display: 'flex',
                        justifyContent: 'end'
                    }}
                >
                    <Button
                        onClick={props.onClose}
                        variant="contained"
                        size="large"
                        color="primary"
                        sx={{
                            color: '#333749',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0
                        }}
                    >CONFIRM</Button>
                    <Button
                        onClick={props.onClose}
                        variant="outlined"
                        size="large"
                        color="primary"
                        sx={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0
                        }}
                    >CANCEL</Button>
                </Box>
            </DialogActions>
        </PraccDialog>
    )
}

function LobbyDetails() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    // @ts-ignore
    const category = location?.state?.category;
    // @ts-ignore
    const mapNumber = location?.state?.mapNumber;
    // @ts-ignore
    const mapBack = location?.state?.mapBack;
    const [numTickets, setNumTickets] = React.useState(0);
    const [numTicketsToday, setNumTicketsToday] = React.useState(0);
    const profile = useSelector((state: any) => state.app.profile) as Profile | null;
    const requestSlider = React.useRef<Slider>(null);

    const [openSwitchPlayerDialog, setOpenSwitchPlayerDialog] = React.useState(false);
    const selectedMap = csgoMaps[mapNumber];
    const { mapsLoaded, maps } = useGameModeMaps("prefire");
    const [mapBackground, setMapBackground] = React.useState(mapBack);
    // @ts-ignore
    const [isCreator, setIsCreator] = React.useState(location?.state?.isCreator)
    async function load() {
        try {
            const res = await axios.get('/api/csgo/deathmatch/rewards/active');
        } catch (e: any) {
            handleError(e)(dispatch);
        }

        if (profile?.ID) {
            try {
                const res = await axios.get('/api/csgo/deathmatch/player/me/rewards');
                setNumTickets(res.data.NumTickets);
                setNumTicketsToday(res.data.NumTicketsToday);
            } catch (e: any) {
                if (e.isAxiosError && e.response?.status === 404) {
                    return;
                }

                handleError(e)(dispatch);
            }
        }
    }
    React.useEffect(() => {
        load();
    }, [profile?.ID]);

    // React.useEffect(() => {
    //     maps.map((map) => {
    //         if (map.Name == selectedMap.label){
    //             setMapBackground(map.Image);
    //         }
    //     })
    //     console.log(maps)
    // }, [mapsLoaded, selectedMap])

    const onChangeRole = (e:any) => {
        setIsCreator((prev: Boolean) => !prev);
    }

    return (
        <Box
            sx={{
                width: '100%',
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#181D31',
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: 2,
                    }}
                >
                    <PraccPaperHeader
                        sx={{ marginBottom: 0, fontSize: '30px', lineHeight: '35.16px', fontWeight: '500' }}
                    >
                        Lobby's name
                        <Button onClick={onChangeRole}>role</Button>
                    </PraccPaperHeader>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                        }}
                    >
                        <Button
                            sx={{
                                width: '40px',
                                height: '40px',
                                borderWidth: 1,
                                borderColor: '#5D6170',
                                borderStyle: 'solid',
                                backgroundColor: '#13182D',
                                borderRadius: '4px',
                                minWidth: '40px',
                                visibility: isCreator ? 'visible' : 'hidden',
                            }}
                        >
                            <img src={SettingIcon} style={{ width: '24px', height: '24px' }} />
                        </Button>
                        <Box
                            sx={{
                                width: '322px',
                                height: '40px',
                                borderWidth: 1,
                                borderColor: '#5D6170',
                                borderStyle: 'solid',
                                backgroundColor: '#13182D',
                                borderRadius: '4px',
                                padding: '10px',
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                visibility: isCreator ? 'visible' : 'hidden',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: -7.03,
                                    width: '54px',
                                }}
                            >
                                <Box sx={{
                                    height: '6.03px',
                                    width: '100%',
                                }} />
                                <Box sx={{
                                    height: '8.03px',
                                    width: '100%',
                                    backgroundColor: '#13182D',
                                }} />

                                <Typography
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        fontWeight: '300',
                                        fontSize: '12px',
                                        lineHeight: '14.06px',
                                        color: '#FFFFFF4D',
                                        left: 2,
                                    }}
                                >Invite link</Typography>
                            </Box>
                            <Box
                                sx={{ flex: 1 }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        lineHeight: '18.75px',
                                        color: 'white',
                                    }}
                                >
                                    https://pracc.com/i/uFuIV
                                </Typography>
                            </Box>
                            <Button
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '4px',
                                    minWidth: '24px',
                                }}
                            >
                                <img src={CopyIcon} style={{ width: '20px', height: '20px' }} />
                            </Button>
                            <Button
                                sx={{
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '4px',
                                    minWidth: '24px',
                                }}
                            >
                                <img src={RefreshIcon} style={{ width: '20px', height: '20px' }} />
                            </Button>
                        </Box>
                        <Button
                            sx={{
                                width: '70px',
                                height: '40px',
                                borderWidth: 1,
                                borderColor: '#F50157',
                                borderStyle: 'solid',
                                backgroundColor: '#13182D',
                                borderRadius: '4px',
                                minWidth: '40px',
                            }}
                        >
                            <img src={ExitLineIcon} style={{ width: '24px', height: '24px' }} />
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        minHeight: '494px',
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            gap: 2,
                            backgroundColor: '#0F162E',
                            borderRadius: '6px',
                            padding: 2,
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                borderRadius: '6px',
                                borderColor: '#5D617080',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                padding: 1,
                                paddingTop: '20px',
                            }}
                        >
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: '400',
                                            fontSize: 15,
                                            lineHeight: '17.58px',
                                        }}
                                    >
                                        Team 1
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '2px',
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                width: '18px',
                                                height: '18px',
                                                minWidth: '18px',
                                                visibility: isCreator ? 'visible' : 'hidden',
                                            }}
                                        >
                                            <img src={MinusIcon} style={{ width: '18px', height: '18px' }} />
                                        </Button>
                                        <Box
                                            sx={{
                                                width: '35px',
                                                height: '25px',
                                                borderRadius: '10px',
                                                backgroundColor: '#242A41',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <img src={UserIcon} style={{ width: '13px', height: '13px' }} />
                                            <Typography
                                                sx={{
                                                    fontSize: '15px',
                                                    fontWeight: '700',
                                                    marginBottom: 0,
                                                    lineHeight: '17.58px',
                                                    marginTop: '2px',
                                                }}
                                            >
                                                5
                                            </Typography>
                                        </Box>
                                        <Button
                                            sx={{
                                                width: '18px',
                                                height: '18px',
                                                minWidth: '18px',
                                                visibility: isCreator ? 'visible' : 'hidden',
                                            }}
                                        >
                                            <img src={PlusIcon} style={{ width: '18px', height: '18px' }} />
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '16px',
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                        <Box
                                            sx={{
                                                width: 15,
                                                height: 15,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <SiFaceit color="#e4621b" size={10} />
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontWeight: '600',
                                                color: '#A7A7A7',
                                                fontSize: '10px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {`322 ELO`}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                        <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                                        <Typography
                                            sx={{
                                                fontWeight: '600',
                                                color: '#A7A7A7',
                                                fontSize: '10px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {`17890`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    gap: 1,
                                    marginTop: '20px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        backgroundColor: '#192038',
                                        gap: '20px',
                                    }}
                                >
                                    <Avatar sx={{ width: 48, height: 48 }} src={'https://picsum.photos/200/300'} />
                                    <Box
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    marginBottom: 0,
                                                    fontSize: '15px',
                                                    fontWeight: '500',
                                                    lineHeight: '17.58px',
                                                }}
                                            >
                                                crisbyyy
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <Box
                                                    sx={{
                                                        width: 15,
                                                        height: 15,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <SiFaceit color="#e4621b" size={10} />
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`322 ELO`}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`17890`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                marginLeft: 1,
                                                flex: 1,
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '2px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '6px',
                                                            height: '6px',
                                                            borderRadius: '3px',
                                                            backgroundColor: '#FF9F46',
                                                        }}
                                                    />
                                                    <img src={MicDisabled} style={{ width: 15, height: 15 }} />
                                                    <img src={ShieldCheckEnabled} style={{ width: 15, height: 15 }} />
                                                </Box>
                                                    <IconButton
                                                        onClick={() => setOpenSwitchPlayerDialog(true)}
                                                        sx={{ width: 18, height: 18, visibility: isCreator ? 'visible' : 'hidden' }}
                                                    >
                                                        <img src={ExchangeIcon} style={{ width: 18, height: 18 }} />
                                                    </IconButton>
                                            </Box>
                                            <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '8px'}}>
                                                <IconButton
                                                    onClick={() => console.log("kick player")}
                                                    sx={{ width: 18, height: 18, visibility: isCreator ? 'visible' : 'hidden' }}
                                                >
                                                    <img src={KickIcon} style={{ width: 18, height: 18 }} />
                                                </IconButton>
                                            </div>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        backgroundColor: '#192038',
                                        gap: '20px',
                                    }}
                                >
                                    <Avatar sx={{ width: 48, height: 48 }} src={'https://picsum.photos/200/300'} />
                                    <Box
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    marginBottom: 0,
                                                    fontSize: '15px',
                                                    fontWeight: '500',
                                                    lineHeight: '17.58px',
                                                }}
                                            >
                                                crisbyyy
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <Box
                                                    sx={{
                                                        width: 15,
                                                        height: 15,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <SiFaceit color="#e4621b" size={10} />
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`322 ELO`}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`17890`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                marginLeft: 1,
                                                flex: 1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '2px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '6px',
                                                            height: '6px',
                                                            borderRadius: '3px',
                                                            backgroundColor: '#FF9F46',
                                                        }}
                                                    />
                                                    <img src={MicDisabled} style={{ width: 15, height: 15 }} />
                                                    <img src={ShieldCheckEnabled} style={{ width: 15, height: 15 }} />
                                                </Box>
                                                <IconButton
                                                    onClick={() => setOpenSwitchPlayerDialog(true)}
                                                    sx={{ width: 18, height: 18, visibility: isCreator ? 'visible' : 'hidden' }}
                                                >
                                                    <img src={ExchangeIcon} style={{ width: 18, height: 18 }} />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        backgroundColor: '#192038',
                                        gap: '20px',
                                    }}
                                >
                                    <Avatar sx={{ width: 48, height: 48 }} src={'https://picsum.photos/200/300'} />
                                    <Box
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    marginBottom: 0,
                                                    fontSize: '15px',
                                                    fontWeight: '500',
                                                    lineHeight: '17.58px',
                                                }}
                                            >
                                                crisbyyy
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <Box
                                                    sx={{
                                                        width: 15,
                                                        height: 15,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <SiFaceit color="#e4621b" size={10} />
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`322 ELO`}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`17890`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                marginLeft: 1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '2px',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '6px',
                                                        height: '6px',
                                                        borderRadius: '3px',
                                                        backgroundColor: '#40FF76',
                                                    }}
                                                />
                                                <img src={MicEnabled} style={{ width: 15, height: 15 }} />
                                                <img src={ShieldCheckEnabled} style={{ width: 15, height: 15 }} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        borderWidth: 1,
                                        borderStyle: 'dotted',
                                        borderColor: '#5D617080',
                                        gap: '20px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '48px',
                                            height: '48px',
                                            borderRadius: '24px',
                                            backgroundColor: '#192038',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '42px',
                                                height: '42px',
                                                borderRadius: '21px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderWidth: '1px',
                                                borderStyle: 'solid',
                                                borderColor: '#0C1227',
                                            }}
                                        >
                                            <img src={SlotUser} style={{ width: 14, height: 20 }} />
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            color: '#A6ACC0',
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: '300', fontSize: 14, lineHeight: '16.41px' }}>
                                            FREE
                                        </Typography>
                                        <Typography sx={{ fontWeight: '300', fontSize: 14, lineHeight: '16.41px' }}>
                                            SLOT
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        borderWidth: 1,
                                        borderStyle: 'dotted',
                                        borderColor: '#5D617080',
                                        gap: '20px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '48px',
                                            height: '48px',
                                            borderRadius: '24px',
                                            backgroundColor: '#192038',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '42px',
                                                height: '42px',
                                                borderRadius: '21px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderWidth: '1px',
                                                borderStyle: 'solid',
                                                borderColor: '#0C1227',
                                            }}
                                        >
                                            <img src={SlotUser} style={{ width: 14, height: 20 }} />
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            color: '#A6ACC0',
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: '300', fontSize: 14, lineHeight: '16.41px' }}>
                                            FREE
                                        </Typography>
                                        <Typography sx={{ fontWeight: '300', fontSize: 14, lineHeight: '16.41px' }}>
                                            SLOT
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '190px',
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundImage: `url(${mapBackground})`,
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    position: 'relative',
                                    width: '100%',
                                    height: '132px',
                                    borderRadius: '5px',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '4px',
                                        backgroundImage: 'linear-gradient(#0E1D4000, #0A0D19E5)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            position: 'absolute',
                                            fontWeight: '500',
                                            fontSize: '22px',
                                            lineHeight: '25.78px',
                                            bottom: '16px',
                                            marginBottom: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img
                                            src={selectedMap.pin}
                                            width={25}
                                            height={25}
                                            alt=""
                                            style={{ flex: 1, alignItems: 'center', paddingRight: 4 }}
                                        />
                                        {selectedMap.label}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: 1,
                                    gap: '7px',
                                    backgroundColor: '#192038',
                                    borderRadius: '2px',
                                    marginTop: '10px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: '300',
                                        fontSize: 13,
                                        lineHeight: '15.23px',
                                        color: '#5D6170',
                                    }}
                                >
                                    Server:
                                </Typography>
                                <ReactCountryFlag countryCode={'US'} style={{ width: 20, height: 20 }} svg />
                                <Typography
                                    sx={{
                                        fontSize: '13px',
                                        lineHeight: '15.23px',
                                        fontWeight: '500',
                                    }}
                                >
                                    USA - TX
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    marginTop: '20px',
                                    display: 'flex',
                                    height: '28px',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box
                                    sx={{
                                        height: '23px',
                                        width: '1px',
                                        backgroundColor: '#181D33',
                                        marginTop: '5px',
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        // paddingRight: '2px',
                                        gap: '2px',
                                    }}
                                >
                                    <img src={MicSlashDisabled} style={{ width: 16, height: 16 }} />
                                    <div
                                        style={{
                                            fontWeight: '400',
                                            fontSize: '7.5px',
                                            lineHeight: '8.79px',
                                            color: '#A6ACC0',
                                        }}
                                    >
                                        Voice
                                        <br />
                                        Off
                                    </div>
                                </Box>
                                <Box
                                    sx={{
                                        height: '23px',
                                        width: '1px',
                                        backgroundColor: '#181D33',
                                        marginTop: '5px',
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        // paddingRight: '2px',
                                        gap: '2px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {/*<Box*/}
                                    {/*    sx={{*/}
                                    {/*        width: '28px',*/}
                                    {/*        height: '28px',*/}
                                    {/*        display: 'flex',*/}
                                    {/*        alignItems: 'center',*/}
                                    {/*        justifyContent: 'center',*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    <img src={FireOnIcon} style={{ width: 14, height: 16 }} />
                                    {/*</Box>*/}
                                    <div
                                        style={{
                                            fontWeight: '400',
                                            fontSize: '7.5px',
                                            lineHeight: '8.79px',
                                            color: '#A6ACC0',
                                        }}
                                    >
                                        Friendly Fire
                                        <br />On
                                    </div>
                                </Box>
                                <Box
                                    sx={{
                                        height: '23px',
                                        width: '1px',
                                        backgroundColor: '#181D33',
                                        marginTop: '5px',
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        // paddingRight: '2px',
                                        gap: '2px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {/*<Box*/}
                                    {/*    sx={{*/}
                                    {/*        width: '28px',*/}
                                    {/*        height: '28px',*/}
                                    {/*        display: 'flex',*/}
                                    {/*        alignItems: 'center',*/}
                                    {/*        justifyContent: 'center',*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    <img src={ShieldCheckEnabled} style={{ width: 16, height: 16 }} />
                                    {/*</Box>*/}
                                    <div
                                        style={{
                                            fontWeight: '400',
                                            fontSize: '7.5px',
                                            lineHeight: '8.79px',
                                            color: '#A6ACC0',
                                        }}
                                    >
                                        Anti-Cheat
                                        <br />On
                                    </div>
                                </Box>
                                <Box
                                    sx={{
                                        height: '23px',
                                        width: '1px',
                                        backgroundColor: '#181D33',
                                        marginTop: '5px',
                                    }}
                                />
                            </Box>
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: '32px',
                                    marginTop: '10px',
                                    backgroundColor: '#2976EE',
                                    color: 'white',
                                    visibility: isCreator ? 'visible' : 'hidden',
                                }}
                            >
                                SHUFFLE TEAMS
                            </Button>
                            <Box
                                sx={{
                                    marginTop: '55px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '185px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '10px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: '700',
                                            fontSize: 25,
                                            lineHeight: '29.3px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        9 / 10
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            height: '40px',
                                        }}
                                    >
                                        READY
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                flex: 1,
                                borderRadius: '6px',
                                borderColor: '#5D617080',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                padding: 1,
                                paddingTop: '20px',
                            }}
                        >
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: '400',
                                            fontSize: 15,
                                            lineHeight: '17.58px',
                                        }}
                                    >
                                        Team 2
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '2px',
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                width: '18px',
                                                height: '18px',
                                                minWidth: '18px',
                                                visibility: isCreator ? 'visible' : 'hidden',
                                            }}
                                        >
                                            <img src={MinusIcon} style={{ width: '18px', height: '18px' }} />
                                        </Button>
                                        <Box
                                            sx={{
                                                width: '35px',
                                                height: '25px',
                                                borderRadius: '10px',
                                                backgroundColor: '#242A41',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <img src={UserIcon} style={{ width: '13px', height: '13px' }} />
                                            <Typography
                                                sx={{
                                                    fontSize: '15px',
                                                    fontWeight: '700',
                                                    marginBottom: 0,
                                                    lineHeight: '17.58px',
                                                    marginTop: '2px',
                                                }}
                                            >
                                                5
                                            </Typography>
                                        </Box>
                                        <Button
                                            sx={{
                                                width: '18px',
                                                height: '18px',
                                                minWidth: '18px',
                                                visibility: isCreator ? 'visible' : 'hidden',
                                            }}
                                        >
                                            <img src={PlusIcon} style={{ width: '18px', height: '18px' }} />
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '16px',
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                        <Box
                                            sx={{
                                                width: 15,
                                                height: 15,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <SiFaceit color="#e4621b" size={10} />
                                        </Box>
                                        <Typography
                                            sx={{
                                                fontWeight: '600',
                                                color: '#A7A7A7',
                                                fontSize: '10px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {`322 ELO`}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                        <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                                        <Typography
                                            sx={{
                                                fontWeight: '600',
                                                color: '#A7A7A7',
                                                fontSize: '10px',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {`17890`}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    gap: 1,
                                    marginTop: '20px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        backgroundColor: '#192038',
                                        gap: '20px',
                                    }}
                                >
                                    <Avatar sx={{ width: 48, height: 48 }} src={'https://picsum.photos/200/300'} />
                                    <Box
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    marginBottom: 0,
                                                    fontSize: '15px',
                                                    fontWeight: '500',
                                                    lineHeight: '17.58px',
                                                }}
                                            >
                                                crisbyyy
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <Box
                                                    sx={{
                                                        width: 15,
                                                        height: 15,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <SiFaceit color="#e4621b" size={10} />
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`322 ELO`}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`17890`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                marginLeft: 1,
                                                flex: 1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '2px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '6px',
                                                            height: '6px',
                                                            borderRadius: '3px',
                                                            backgroundColor: '#FF9F46',
                                                        }}
                                                    />
                                                    <img src={MicDisabled} style={{ width: 15, height: 15 }} />
                                                    <img src={ShieldCheckEnabled} style={{ width: 15, height: 15 }} />
                                                </Box>
                                                <IconButton
                                                    onClick={() => setOpenSwitchPlayerDialog(true)}
                                                    sx={{ width: 18, height: 18, visibility: isCreator ? 'visible' : 'hidden' }}
                                                >
                                                    <img src={ExchangeIcon} style={{ width: 18, height: 18 }} />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        backgroundColor: '#192038',
                                        gap: '20px',
                                    }}
                                >
                                    <Avatar sx={{ width: 48, height: 48 }} src={'https://picsum.photos/200/300'} />
                                    <Box
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    marginBottom: 0,
                                                    fontSize: '15px',
                                                    fontWeight: '500',
                                                    lineHeight: '17.58px',
                                                }}
                                            >
                                                crisbyyy
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <Box
                                                    sx={{
                                                        width: 15,
                                                        height: 15,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <SiFaceit color="#e4621b" size={10} />
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`322 ELO`}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`17890`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                marginLeft: 1,
                                                flex: 1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '2px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '6px',
                                                            height: '6px',
                                                            borderRadius: '3px',
                                                            backgroundColor: '#FF9F46',
                                                        }}
                                                    />
                                                    <img src={MicDisabled} style={{ width: 15, height: 15 }} />
                                                    <img src={ShieldCheckEnabled} style={{ width: 15, height: 15 }} />
                                                </Box>
                                                <IconButton
                                                    onClick={() => setOpenSwitchPlayerDialog(true)}
                                                    sx={{ width: 18, height: 18, visibility: isCreator ? 'visible' : 'hidden' }}
                                                >
                                                    <img src={ExchangeIcon} style={{ width: 18, height: 18 }} />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        backgroundColor: '#192038',
                                        gap: '20px',
                                    }}
                                >
                                    <Avatar sx={{ width: 48, height: 48 }} src={'https://picsum.photos/200/300'} />
                                    <Box
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    marginBottom: 0,
                                                    fontSize: '15px',
                                                    fontWeight: '500',
                                                    lineHeight: '17.58px',
                                                }}
                                            >
                                                crisbyyy
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <Box
                                                    sx={{
                                                        width: 15,
                                                        height: 15,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <SiFaceit color="#e4621b" size={10} />
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`322 ELO`}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`17890`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                marginLeft: 1,
                                                flex: 1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '2px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '6px',
                                                            height: '6px',
                                                            borderRadius: '3px',
                                                            backgroundColor: '#FF9F46',
                                                        }}
                                                    />
                                                    <img src={MicDisabled} style={{ width: 15, height: 15 }} />
                                                    <img src={ShieldCheckEnabled} style={{ width: 15, height: 15 }} />
                                                </Box>
                                                <IconButton
                                                    onClick={() => setOpenSwitchPlayerDialog(true)}
                                                    sx={{ width: 18, height: 18, visibility: isCreator ? 'visible' : 'hidden' }}
                                                >
                                                    <img src={ExchangeIcon} style={{ width: 18, height: 18 }} />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        backgroundColor: '#192038',
                                        gap: '20px',
                                    }}
                                >
                                    <Avatar sx={{ width: 48, height: 48 }} src={'https://picsum.photos/200/300'} />
                                    <Box
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    marginBottom: 0,
                                                    fontSize: '15px',
                                                    fontWeight: '500',
                                                    lineHeight: '17.58px',
                                                }}
                                            >
                                                crisbyyy
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <Box
                                                    sx={{
                                                        width: 15,
                                                        height: 15,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <SiFaceit color="#e4621b" size={10} />
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`322 ELO`}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`17890`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                marginLeft: 1,
                                                flex: 1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '2px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '6px',
                                                            height: '6px',
                                                            borderRadius: '3px',
                                                            backgroundColor: '#FF9F46',
                                                        }}
                                                    />
                                                    <img src={MicDisabled} style={{ width: 15, height: 15 }} />
                                                    <img src={ShieldCheckEnabled} style={{ width: 15, height: 15 }} />
                                                </Box>
                                                <IconButton
                                                    onClick={() => setOpenSwitchPlayerDialog(true)}
                                                    sx={{ width: 18, height: 18, visibility: isCreator ? 'visible' : 'hidden' }}
                                                >
                                                    <img src={ExchangeIcon} style={{ width: 18, height: 18 }} />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        backgroundColor: '#192038',
                                        gap: '20px',
                                    }}
                                >
                                    <Avatar sx={{ width: 48, height: 48 }} src={'https://picsum.photos/200/300'} />
                                    <Box
                                        sx={{
                                            flex: 1,
                                            display: 'flex',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    marginBottom: 0,
                                                    fontSize: '15px',
                                                    fontWeight: '500',
                                                    lineHeight: '17.58px',
                                                }}
                                            >
                                                crisbyyy
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <Box
                                                    sx={{
                                                        width: 15,
                                                        height: 15,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <SiFaceit color="#e4621b" size={10} />
                                                </Box>
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`322 ELO`}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                <img src={SteamIcon} style={{ width: 15, height: 15 }} />
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600',
                                                        color: '#A7A7A7',
                                                        fontSize: '10px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {`17890`}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                marginLeft: 1,
                                                flex: 1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '2px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '6px',
                                                            height: '6px',
                                                            borderRadius: '3px',
                                                            backgroundColor: '#FF9F46',
                                                        }}
                                                    />
                                                    <img src={MicDisabled} style={{ width: 15, height: 15 }} />
                                                    <img src={ShieldCheckEnabled} style={{ width: 15, height: 15 }} />
                                                </Box>
                                                <IconButton
                                                    onClick={() => setOpenSwitchPlayerDialog(true)}
                                                    sx={{ width: 18, height: 18, visibility: isCreator ? 'visible' : 'hidden' }}
                                                >
                                                    <img src={ExchangeIcon} style={{ width: 18, height: 18 }} />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <SwitchPlayerDialog
                            open={openSwitchPlayerDialog}
                            onClose={() => setOpenSwitchPlayerDialog(false)}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '304px',
                            backgroundColor: '#0F162E',
                            borderRadius: '6px',
                            padding: 2,
                        }}
                    >
                        <PraccPaperHeader
                            sx={{ marginBottom: 0, fontSize: '18px', lineHeight: '31px', fontWeight: '500' }}
                        >
                            CHAT
                        </PraccPaperHeader>
                        {/*<Box*/}
                        {/*    sx={{*/}
                        {/*        flex: 1,*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <AsyncChatContent chatId={34436} />*/}
                        {/*</Box>*/}
                    </Box>
                </Box>
                <Box
                    sx={{
                        height: '1px',
                        backgroundColor: '#5D617080',
                        marginTop: 2,
                        marginLeft: 2,
                        marginRight: 2,
                    }}
                />
                {category == 'Application' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            padding: '20px',
                            paddingTop: '10px',
                            paddingRight: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <PraccPaperHeader
                                sx={{ marginBottom: 0, fontSize: '18px', lineHeight: '31px', fontWeight: '500' }}
                            >
                                JOIN REQUESTS
                            </PraccPaperHeader>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '10px',
                                }}
                            >
                                <Button
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                        backgroundColor: '#24315D',
                                        borderRadius: '2px',
                                        minWidth: '30px',
                                    }}
                                    disabled={true}
                                >
                                    <ChevronLeft sx={{ width: '24px', height: '24px', color: '#ffffff29' }} />
                                </Button>
                                <Button
                                    sx={{
                                        width: '30px',
                                        height: '30px',
                                        backgroundColor: '#24315D',
                                        borderRadius: '2px',
                                        minWidth: '30px',
                                    }}
                                    disabled={true}
                                >
                                    <ChevronRight sx={{ width: '24px', height: '24px', color: '#ffffff29' }} />
                                </Button>
                            </Box>
                        </Box>
                        <>
                            <Slider
                                ref={requestSlider}
                                arrows={false}
                                infinite={false}
                                dots={false}
                                slidesToShow={8}
                                slidesToScroll={8}
                            >
                                {requests.map((r, index) => (
                                    <RequestCard request={r} key={r.ID + `${index}`} isCreator={isCreator} />
                                ))}
                            </Slider>
                        </>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default LobbyDetails;
